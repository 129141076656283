import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router);


export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/index.html',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                protected: true
            }
        },
        {
            path: '/about/:id',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/test/daily/:id',
            name: 'startDailyTest',
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/test/weekly/:id',
            name: 'startWeeklyTest',
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/solution/:id',
            name: 'solution',
            component: () => import(/* webpackChunkName: "solution" */ './views/Solution.vue'),
            meta: {
                protected: true
            }
        }, {
            path: '/solution/daily/:id',
            name: 'solutionDaily',
            component: () => import(/* webpackChunkName: "solution" */ './views/Solution.vue'),
            meta: {
                protected: true
            }
        }, {
            path: '/solution/weekly/:id',
            name: 'solutionWeekly',
            component: () => import(/* webpackChunkName: "solution" */ './views/Solution.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/register',
            name: 'register',
            component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import(/* webpackChunkName: "logout" */ './views/Logout.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/results',
            name: 'results',
            component: () => import(/* webpackChunkName: "results" */ './views/Results.vue'),
            meta: {
                protected: true
            }
        },
        // {
        //     path: '/tests',
        //     name: 'tests',
        //
        //     component: () => import(/* webpackChunkName: "tests" */ './views/Tests.vue')
        //     ,
        //     meta: {
        //         protected: true
        //     }
        // },
        {
            path: '/schedule',
            name: 'schedule',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "schedule" */ './views/Schedule.vue')
        },
        {
            path: '/subscribe',
            name: 'subscribe',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "subscribe" */ './views/Subscribe.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/forum',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "forum" */ './views/forum/Forum.vue'),
            meta: {
                protected: true
            },
            children: [
                {
                    path: '',
                    component: () => import(/* webpackChunkName: "forum" */ './views/forum/Home.vue'),
                },
                {

                    // UserProfile will be rendered inside User's <router-view>
                    // when /forum/:id is matched
                    path: 'post/insert/:id?',
                    name: "insertPost",
                    component: () => import(/* webpackChunkName: "forum" */ './views/forum/Insert.vue'),

                },
                {

                    // UserProfile will be rendered inside User's <router-view>
                    // when /forum/:id is matched
                    path: 'post/:id',
                    component: () => import(/* webpackChunkName: "forum" */ './views/forum/Post.vue'),

                },
                {

                    // UserProfile will be rendered inside User's <router-view>
                    // when /forum/:id is matched
                    path: 'hashtag/:hashtag',
                    component: () => import(/* webpackChunkName: "forum" */ './views/forum/Hashtag.vue'),

                },
                {

                    // UserProfile will be rendered inside User's <router-view>
                    // when /forum/:id is matched
                    path: 'user/:username',
                    name: 'forumUser',
                    component: () => import(/* webpackChunkName: "forum" */ './views/forum/UserPosts.vue'),

                },
                // {
                //     // UserPosts will be rendered inside User's <router-view>
                //     // when /user/:id/posts is matched
                //     path: 'posts',
                //     component: UserPosts
                // }
            ]
        },
        {
            path: '/rapidfire',
            name: 'rapidfire',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "rapidfire" */ './views/Rapidfire.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/ibqs',
            name: 'ibqs',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "ibqs" */ './views/Ibqs.vue'),
            meta: {
                protected: true
            }
        },
        {
            path: '/subjects',
            name: 'subjects',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "subjects" */ './views/Subjects.vue')
        },
        {
            path: '/subjects/practise/:id/:tag?',
            name: 'practise',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "practise" */ './views/Practise.vue')
        },
        {
            path: '/daily-tests',
            name: 'daily-tests',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "daily" */ './views/DailyTests.vue')
        },
        {
            path: '/weekly-tests',
            name: 'weekly-tests',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "weekly" */ './views/WeeklyTests.vue')
        },
        {
            path: '/profile',
            name: 'profile',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "profile" */ './views/UserProfile.vue')
        },
        {
            name: 'Privacy Policy',
            path: '/privacy-policy',
            beforeEnter() {
                window.open("https://dentalpockets.com/pp.html",
                    '_blank');
            }
        },
        {
            name: 'Terms',
            path: '/tnc',
            beforeEnter() {
                window.open("https://dentalpockets.com//tnc.html",
                    '_blank');
            }
        },
        {
            name: 'FAQs',
            path: '/faqs',
            beforeEnter() {
                window.open("https://dentalpockets.com/FAQ.html",
                    '_blank');
            }
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve) => {
                setTimeout(() => {
                    return resolve(savedPosition)
                }, 500)
            });

        } else {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({x: 0, y: 0})
                }, 500)
            })
        }
    }


})
