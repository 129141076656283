import axios from 'axios';

// import router from "./router";


const CryptoJS = require("crypto-js");

const CryptoJSAesJson = {
    stringify: function (cipherParams) {
        let j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        let j = JSON.parse(jsonStr);
        let cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
        return cipherParams;
    }
};

// API_URL = 'http://www.duparedentalclinic.com/api/home';
export class APIService {


    constructor() {
        //    API_URL = 'http://www.duparedentalclinic.com/api/home';


    }

    static getAllResults(uid) {
        // console.log(uid);
        let URL = APIService.getAPI_URL();
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.get(URL + "listSolutions/" + uid, config).then(response => response.data);
    }

    static getAllSubjectsFromServer() {
        let URL = APIService.getAPI_URL();
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.get(URL + "subjects", config).then(response => response.data);
    }

    // static getTestSolutionFromServer(testID) {
    //     let URL = APIService.getAPI_URL();
    //     let config = {
    //         headers: {
    //             "DentalPocketsSecurityServer": localStorage.getItem("uid")
    //         }
    //     };
    //     return axios.get(URL + "test/solution/" + testID, config).then(response => response.data);
    // }


    // static getTodaysTest(testID) {
    //     let URL = APIService.getAPI_URL();
    //     return axios.get(URL + "test/" + testID, {crossdomain: true}).then(response => response.data);
    // }
    //
    // static getAllTests() {
    //     let URL = APIService.getAPI_URL();
    //     let config = {
    //         headers: {
    //             "DentalPocketsSecurityServer": localStorage.getItem("uid")
    //         }
    //     };
    //     return axios.get(URL + "list", config).then(response => response.data);
    // }

    static getAPI_URL() {
        return 'https://duparedentalclinic.com/be-webapp/';
    }

    static logoutFromServer() {
        let URL = APIService.getAPI_URL();
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.get(URL + "logout", config).then(response => response.data);

    }


    getDataFromHome() {

        let URL = APIService.getAPI_URL();
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.get(URL + "home", config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                if (error.response) {
                    // // The request was made and the server responded with a status code
                    // // that falls out of the range of 2xx
                    // // eslint-disable-next-line no-console
                    // console.log(error.response.data);
                    // // eslint-disable-next-line no-console
                    // console.log(error.response.status);

                    if (error.response.status === 408) {
                        // router.replace("/login")
                        return error.response;
                    }


                    // // eslint-disable-next-line no-console
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // // The request was made but no response was received
                    // // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // // http.ClientRequest in node.js
                    // // eslint-disable-next-line no-console
                    // console.log(error.request);

                } else {
                    // // Something happened in setting up the request that triggered an Error
                    // // eslint-disable-next-line no-console
                    // console.log('Error', error.message);
                }
                // // eslint-disable-next-line no-console
                // console.log(error.config);
            });


    }

    getForumUpdates() {
        let URL = APIService.getAPI_URL();
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.get(URL + "forum-updates", config).then(response => response.data);
    }

    getUpdates() {
        let URL = APIService.getAPI_URL();
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.get(URL + "updates", config).then(response => response.data);
    }

    getUserDetails(uuid) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uuid": uuid,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "user-data", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    tagMCQ(mid, tag) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "mid": mid,
            "uid": shuffled,
            "tag": tag
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "mcq/favorite", data, config).then(response => response.data);
    }

    saveRapidfire(score) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled,
            "score": score
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "rapidfire/score", data, config).then(response => response);
    }

    sendLogin(data) {
        let URL = APIService.getAPI_URL();
        return axios.post(URL + "login", data).then(response => response.data);

    }

    getRapidfireHighscore() {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "rapidfire/hs", data, config).then(response => response.data);
    }


    getIBQFromServer() {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "ibqs/1", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));


        })
    }

    getRapidfireMCQFromServer() {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "rapidfire/1", data, config).then(response => {
            return {
                "mcqs": this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid")),

            };

        })
    }


    getSubscriptionsFromServer() {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "user/subscriptions", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));


        })
    }

    decryptResponse(d, k) {
        return JSON.parse(JSON.parse(CryptoJS.AES.decrypt(d, k, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8)))
    }

    getSubjectMCQFromServer(subjectid, tag) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "sid": subjectid,
            "uid": shuffled,
            "tag": tag
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "mcq/1", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
            // if (response.data.r) {
            //     return {
            //         "mcqs": this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid")),
            //         "r": response.data
            //     };
            // } else {
            //     return {"r": response.data};
            // }

        });
    }

    sendAttemptedToServer(mcqid, attempted, timeTaken) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "mid": mcqid,
            "uid": shuffled,
            "att": attempted,
            "tt": timeTaken
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "mcq/attempt", data, config).then(response => response.data);
    }


    getUpcomingDailyTests(pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "pageno": pageno,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "dailyTests", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getUpcomingWeeklyTests(pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "pageno": pageno,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "weeklyTests", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getTestRankings(testid, type) {
        //type 1 - Daily
        //type 2 - Weekly
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "testid": testid,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        let fURL;
        if (type === 1) {
            fURL = URL + "dailyTests/rankings"
        } else {
            fURL = URL + "weeklyTests/rankings"
        }


        return axios.post(fURL, data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getUpcomingPreviousDailyTests(pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "pageno": pageno,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "dailyTests/past", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    getUpcomingPreviousWeeklyTests(pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "pageno": pageno,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "weeklyTests/past", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getDailyTestFromServer(testid) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "testid": testid,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "dailyTests/test", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getWeeklyTestFromServer(testid) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "testid": testid,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "weeklyTests/test", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    sendDailyTestScoreToServer(testid, arr) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "testid": testid,
            "responses": arr,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "dailyTests/submit", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    sendWeeklyTestScoreToServer(testid, arr) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "testid": testid,
            "responses": arr,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "weeklyTests/submit", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    getDailyTestSolution(testid) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "testid": testid,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "dailyTests/solution", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getWeeklyTestSolution(testid) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "testid": testid,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "weeklyTests/solution", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    // eslint-disable-next-line no-unused-vars
    getUserLocation() {
        // let URL = "http://ip-api.com/json/" + ip + "?fields=status,message,country,regionName,city,isp,mobile,query";
        let URL = "https://ipapi.co/json/";
        return axios.get(URL).then(function (response) {
            // eslint-disable-next-line no-console
            // console.log(response);
            if (response.data.error) {
                localStorage.removeItem("ipdetails");
                return "";

            } else {
                localStorage.setItem("ipdetails", JSON.stringify(response.data));
                return response.data;
            }
        });

    }

    sendFeedback(avatarImage, feedback) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        // let data = {
        //     "testid": testid,
        //     "uid": shuffled
        // };
        let form = new FormData();
        form.append('userAvatar', avatarImage);
        form.append('feedback', feedback);
        form.append('uid', shuffled);

        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "feedback", form, config).then(response => {

            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });

    }

    sendUserAvatar(avatarImage, filename) {
        let URL = APIService.getAPI_URL();
        // let shuffled = localStorage.getItem("uid").split('').sort(function () {
        //     return 0.5 - Math.random()
        // }).join('');

        // let data = {
        //     "testid": testid,
        //     "uid": shuffled
        // };
        let form = new FormData();
        form.append('userAvatar', avatarImage, filename);

        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "userAvatar", form, config).then(response => response.data);

    }

    sendProfileUpdateToServer(arr) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "profile": arr,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "userProfile/submit", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    createPaymentRequest(arr) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "data": arr,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "createPaymentRequest", data, config).then(response => {
            if (response.status === 401) {
                return 401;
            } else {
                return response.data;
            }
        });
    }

    getProfileInfoFromServer() {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "userProfile", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    sendSuggestion(score, suggestion) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "score": score,
            "suggestion": suggestion,
            "uid": shuffled
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "suggestion", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    markFavUnfav(discussionID) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "did": discussionID,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/fav", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    saveDiscussionsToServer(mcqid, discussion) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "mid": mcqid,
            "uid": shuffled,
            "discussion": discussion
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/10", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getDiscussionsFromServer(mcqid) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "mid": mcqid,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    //
    //
    // sendUserAvatar(avatarImage, filename) {
    //
    //     let URL = APIService.getAPI_URL();
    //
    //
    //     axios.post(URL + '/userAvatar', form, {
    //         headers: {
    //             'Content-Type': `multipart/form-data;`
    //         }
    //     }).then((res) => {
    //         return res.data;
    //     }).catch((err) => {
    //         // eslint-disable-next-line no-console
    //         console.log(err);
    //     });
    // }


    postToPost(bodyText, replyToPostID, imageBlob) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');
        let fd = new FormData();

        if (imageBlob) {
            fd.append('file', imageBlob, 'filename.jpg');
        }
        // let filename = this.image.name;
        fd.append('body', bodyText);
        fd.append('rid', replyToPostID);
        fd.append('uid', shuffled);


        // let data = {
        //     "body": bodyText,
        //     "rid": replyToPostID,
        //     "uid": shuffled
        // };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/post", fd, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    showAllPosts(pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled,
            "pageno": pageno
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/show-posts", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    showAllReplies(id, pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled,
            "postid": id,
            "pageno": pageno
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/show-posts-with-id", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    showHashtags(requestedHashtag, pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "uid": shuffled,
            "hashtag": requestedHashtag,
            "pageno": pageno
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/show-posts-with-hashtag", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    markPostFavUnfav(postID) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "pid": postID,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/posts-fav", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    getUserNames(userName) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "un": userName,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/username", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getHashtags(userName) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "un": userName,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/hashtag", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    getUserPosts(userName, pageno = 1) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "un": userName,
            "uid": shuffled,
            "pageno": pageno
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/show-posts-username", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getUserDetailsFromUserName(userName) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "un": userName,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/user-data-username", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    followOrUnfollowUser(userName) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "un": userName,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/follow-or-not", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }


    getFollowingList(userName) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "un": userName,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/show-following", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    getFollowersList(userName) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "un": userName,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/show-followers", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    reportPostID(postId) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "id": postId,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/report", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }

    deletePost(postId) {
        let URL = APIService.getAPI_URL();
        let shuffled = localStorage.getItem("uid").split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        let data = {
            "id": postId,
            "uid": shuffled,
        };
        let config = {
            headers: {
                "DentalPocketsSecurityServer": localStorage.getItem("uid")
            }
        };
        return axios.post(URL + "discussions/delete", data, config).then(response => {
            return this.decryptResponse(response.data.r, shuffled + localStorage.getItem("uid"));
        });
    }
}
