<template>

  <v-card

      class="mx-auto"
      elevation="24"
  >
    <v-toolbar
        :color="($vuetify.theme.dark) ? 'dark' : 'white'"

    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>

    </v-toolbar>
    <v-list>
      <v-list-item
          v-for="(data, i) in toppers"
          :key="i"
          @click="showProfile(data)"
      >
        <v-list-item-action class="mr-4">
          #{{ parseInt(i + 1) }}
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.info.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.score }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-img
              :alt="`${data.info.title} Avatar`"
              :src="data.info.avatar"></v-img>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
    <v-toolbar>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark text @click="navigate">Attempt Now
        <v-icon right> mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="text-center">
      <v-dialog
          v-model="userDialog"
          overlay-color="white"
          width="400"
      >

        <v-card class="overflow-y-auto">

          <v-list :style="`background-color:  ${info.color}`" class="text-center ">
            <v-list-item dark>
              <v-list-item-content>
                <v-list-item-title class="headline">{{ info.name }}</v-list-item-title>

              </v-list-item-content>
              <v-list-item-icon>
                <v-btn dark icon @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

          <v-card-text class="text-center">
            <v-row align="center" class="mt-3" justify="center">
              <v-avatar
                  class="profile"
                  color="grey"
                  size="80"

              >
                <!--suppress HtmlUnknownTarget -->
                <v-img :src="info.avatar"/>
              </v-avatar>
            </v-row>
            <v-row align="center" justify="center">
              <div class="text-center mt-2">{{ info.un }}</div>
            </v-row>
          </v-card-text>
          <v-card-text class="text-center">
            <!--suppress JSUnresolvedVariable -->
            <h4 class="title font-weight-light">
              {{ info.dj }}
            </h4>
            <div v-if="info.shareGender">
              <h4 v-if="info.gender ==='Other'" class="title font-weight-light">
                <v-icon color="grey" left>mdi-gender-transgender</v-icon>
              </h4>
              <h4 v-else-if="info.gender ==='Female'" class="title font-weight-light">
                <v-icon color="grey" left>mdi-gender-female</v-icon>
              </h4>
              <h4 v-else-if="info.gender ==='Male'" class="title font-weight-light">
                <v-icon color="grey" left>mdi-gender-male</v-icon>
              </h4>
            </div>
            <h4 v-if="info.shareLocation" class="font-weight-light">
              <v-icon color="grey" left>mdi-map-marker</v-icon>
              {{ info.city }}, {{ info.state }}
            </h4>

            <!--                        <h6 class="category text-gray font-weight-thin mb-3">-->

            <!--                        </h6>-->

            <!--                        <h4 class="card-title font-weight-light"></h4>-->
            <p class="card-description font-weight-light">{{ info.about }}</p>
            <!--                        <v-btn-->
            <!--                                class="font-weight-light"-->
            <!--                                color="success"-->
            <!--                                rounded-->
            <!--                        >Follow-->
            <!--                        </v-btn>-->
          </v-card-text>
          <v-card-text v-if="user">
            <span class=" caption font-weight-bold text-left">User Statistics:</span>
            <v-list>
              <template>
                <v-list-item>
                  <v-list-item-action>
                    {{ user.userMCQsAttempted }}
                  </v-list-item-action>
                  <v-list-item-title>MCQs Attempted</v-list-item-title>
                </v-list-item>
                <v-divider/>

                <v-list-item>
                  <v-list-item-action>
                    {{ user.userDailyTestAttempted }}
                  </v-list-item-action>
                  <v-list-item-title>Daily Tests Attempted</v-list-item-title>
                </v-list-item>
                <v-divider/>

                <v-list-item>
                  <v-list-item-action>
                    {{ user.userRapidfireHS }}
                  </v-list-item-action>
                  <v-list-item-title>Rapidfire High Score</v-list-item-title>
                </v-list-item>

                <v-divider/>


              </template>

            </v-list>
          </v-card-text>

        </v-card>
      </v-dialog>
    </div>
  </v-card>

</template>

<script>
export default {
  name: "NewTopperComponent",
  components: {},
  props: {
    colors: {},
    toppers: {},
    title: {},

  },
  computed: {},
  data() {
    return {
      uid: '',
      user: [],
      info: [],
      userDialogData: [],
      userDialog: false,
    }
  },
  methods: {
    navigate() {
      if (this.title === "Rapidfire Toppers") {
        this.$router.push("rapidfire");
      } else if (this.title === "Today's Test Toppers") {
        this.$router.push({'name': 'daily-tests'})
      } else if (this.title === "Weekly Test Toppers") {
        this.$router.push({'name': 'weekly-tests'})
      }

    },
    showProfile(data) {
      let app = this;

      app.info = data.info;
      app.user.userMCQsAttempted = data.user.m;
      app.user.userDailyTestAttempted = data.user.d;
      app.user.userRapidfireHS = data.user.r;
      app.userDialog = true;

    },
    closeDialog() {
      this.userDialog = false;
    }
  },
}
</script>

<style scoped>

</style>