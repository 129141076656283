<template>
  <div id="app" ref="getMe" @contextmenu.prevent="nomenu">
    <v-app id="inspire" :dark="(isDark === 'dark')" class="hidden-print-only d-print-none hideMeInPrint">
      <v-navigation-drawer v-model="drawer" app fixed>
        <v-list v-if="authenticated" class="pa-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <!--suppress HtmlUnknownTarget -->
              <v-img :src="photoUrl" alt="user image"/>
            </v-list-item-avatar>
            <v-list-item-content>

              <v-list-item-title>{{ userName }}</v-list-item-title>
              <v-list-item-subtitle>
                <router-link to="/profile"> Edit Profile</router-link>
                <!--                                Edit Profile-->
                <!--                                                            <v-btn>Edit Profile</v-btn>-->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="authenticated" dense>
          <div v-for="item in items" :key="item.name">
            <v-list-item v-if="!item.link && !item.action" :id="item.id" :key="item.name"
                         :to="item.to"

            >

              <!--                            @click="goto(item.name, item.path)"-->
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ UC(item.name) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action-text v-if="item.isNew">
                <div class="blue darken-3 pr-2 pl-2 white--text">NEW</div>
              </v-list-item-action-text>

            </v-list-item>
            <v-list-item v-else-if="!item.link && item.action" :key="item.name"
                         @click="feedback"

            >

              <!--                            @click="goto(item.name, item.path)"-->
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ UC(item.name) }}</v-list-item-title>
              </v-list-item-content>

            </v-list-item>
            <v-list-group v-else :key="item.text" no-action>
              <template v-slot:activator>
                <v-list-item style="padding-left: 0;">
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ UC(item.text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item v-for="sublink in item.link" :key="sublink.to" :to="sublink.to">
                <v-list-item-action>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ UC(sublink.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


            </v-list-group>
          </div>
        </v-list>
        <v-list v-else>
          <v-list-item v-for="item in loggedOutItems" :key="item.name" @click="goto(item.name)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ UC(item.name) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <template v-slot:append>
          <div class="pa-2">
            <span class="overline">Connect with us</span><br>

            <v-btn icon title="Connect on Facebook" @click="openSocialMedia(1)">
              <v-icon size="24px">mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon title="Connect on Instagram" @click="openSocialMedia(2)">
              <v-icon size="24px">mdi-instagram</v-icon>
            </v-btn>
            <v-btn icon title="Connect on Twitter" @click="openSocialMedia(3)">
              <!--              <v-icon  size="24px">mdi-Twitter</v-icon>-->
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn icon title="Connect on Telegram" @click="openSocialMedia(4)">
              <v-icon size="24px"> mdi-telegram</v-icon>
            </v-btn>

            <v-btn icon title="Download Android App" @click="openSocialMedia(5)">
              <v-icon size="24px"> mdi-android-debug-bridge</v-icon>
            </v-btn>


          </div>
        </template>
      </v-navigation-drawer>

      <v-app-bar

          :color="($vuetify.theme.dark) ? 'dark' : 'indigo'"
          app
          dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
        <!--suppress HtmlUnknownTarget -->
        <img alt="App Logo" class="mr-2" height="30" src="@/assets/logo1.png"/>
        <v-toolbar-title @click="goHome">Dental Pockets</v-toolbar-title>


        <v-spacer/>

        <v-btn
            id="mode-switcher" icon
            @click="toggleDarkMode"
        >
          <!--          :color="($vuetify.theme.dark) ? 'primary' : 'primary lighten-4'"-->
          <v-icon>
            {{ ($vuetify.theme.dark) ? 'mdi-weather-night' : 'mdi-weather-sunny' }}
          </v-icon>
        </v-btn>

        <v-menu
            v-model="forumMenu"
            :close-on-content-click="false"
            :nudge-width="300"
            offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>mdi-star-four-points-outline</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-toolbar color="indigo" dark>

              <v-toolbar-title>Forum Updates</v-toolbar-title>
              <v-spacer/>
              <v-toolbar-items>
                <v-btn dark text @click="forumMenu =false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-progress-linear v-show="newForumUpdatesProgress" indeterminate/>
            <div class="text-center">
              <v-card-subtitle v-if="newForumUpdates.length>0">
                <v-btn outlined rounded small @click="loadNewForumUpdates">New Updates</v-btn>
              </v-card-subtitle>
            </div>
            <v-divider/>


            <v-list three-line>

              <div v-if="forumUpdates.length > 0">
                <template v-for="(update, index) in forumUpdates">
                  <v-list-item :key="'r'+index">

                    <v-list-item-avatar class="showPointer" size="50"
                                        @click="userClick(update)">
                      <!--suppress HtmlUnknownTarget -->
                      <img :alt="update.name" :src="update.img">
                    </v-list-item-avatar>
                    <v-list-item-content @click="showForumPost(update.post)">
                      <v-list-item-title :class="(isDark === 'dark')?'':'indigo--text'" class="showPointer">
                        {{ update.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="showPointer">
                        <!--suppress JSUnresolvedVariable -->
                        <span>{{ update.wm }}</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="showPointer">
                        <span class="pink--text">Mentioned you in a post</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action-text @click="showForumPost(update.post)">
                      {{ update.time }}
                    </v-list-item-action-text>


                  </v-list-item>

                  <v-divider :key="index" style="color:red"/>
                </template>
              </div>
              <div v-else>
                <v-row align="center" justify="center">
                  <div class="caption mt-6">No one mentioned you in their post.</div>
                </v-row>
                <v-row align="center" justify="center">
                  <v-btn class="ma-3" color="success" outlined rounded @click="showForum">Visit
                    Forum
                  </v-btn>
                </v-row>


              </div>

            </v-list>


          </v-card>
        </v-menu>

        <v-menu
            v-model="updatesMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>mdi-bell-ring</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-toolbar color="indigo" dark>

              <v-toolbar-title>Recent Updates</v-toolbar-title>
              <v-spacer/>
              <v-toolbar-items>
                <v-btn dark text @click="updatesMenu =false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-progress-linear v-show="newUpdatesProgress" indeterminate/>
            <div class="text-center">
              <v-card-subtitle v-if="newUpdates.length>0">
                <v-btn outlined rounded small @click="loadNewUpdates">New Updates</v-btn>
              </v-card-subtitle>
            </div>
            <v-divider/>


            <template v-for="(update, index) in updates">
              <v-list-item :key="'r'+index">

                <v-list-item-avatar class="showPointer"
                                    @click="userClick(update)">
                  <!--suppress HtmlUnknownTarget -->
                  <img :alt="update.name" :src="update.img">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title :class="(isDark === 'dark')?'':'indigo--text'" class="showPointer "
                                     @click="userClick(update)">
                    {{ update.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="showPointer" @click="pClick(update)">began
                    <span class="pink--text">{{ update.p }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>


              </v-list-item>

              <v-divider :key="index" style="color:red"/>
            </template>


          </v-card>
        </v-menu>

      </v-app-bar>


      <v-main class="overflow-y-auto">
        <v-progress-linear v-show="showProgress" :indeterminate="true" class="ma-0"/>
        <!-- <v-container fluid> -->
        <!--                <keep-alive>-->
        <transition
            mode="out-in"
            name="fade"
            @afterEnter="afterEnter"
            @beforeLeave="beforeLeave"
            @enter="enter"


        >


          <router-view v-keep-scroll-position/>
        </transition>
        <!--                </keep-alive>-->
        <!--                <router-view/>-->
        <!-- </v-container> -->
      </v-main>
      <v-footer v-if="!isMobile"
                :color="($vuetify.theme.dark) ? 'dark' : 'indigo'"

                app>
        <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
        <v-spacer/>
        <span class="white--text ma-2">
          Made with
          <v-icon color="red darken-2" small>mdi-cards-heart</v-icon> by Ankit
        </span>
      </v-footer>

      <!-- <v-overlay :value="showProgress">
      </v-overlay>-->
    </v-app>

    <div class="text-center">
      <v-dialog
          v-model="userDialog"
          overlay-color="white"
          width="400"
      >

        <ProfileComponent v-if="userDialogData.info" :info="userDialogData.info" :user="userDialogData.user"/>
        <div v-else class="text-center" style="background-color: white">
          <v-progress-circular :indeterminate="true" class="pa-4 ma-4" size="50" value="0"/>
        </div>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
          v-model="userDialogRoute"
          width="500"
      >


        <v-card>

          <v-card-title>
            <v-icon left>mdi-help</v-icon>
            <span class="title font-weight-light">Continue with {{ userDialogRouteData.name }}</span>
          </v-card-title>


          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" @click="navigate(userDialogRouteData.route)">
              Yes
            </v-btn>
            <v-btn color="white" @click="userDialogRoute =false">
              No
            </v-btn>
          </v-card-actions>


        </v-card>
      </v-dialog>
    </div>

    <v-dialog

        v-model="feedbackDialog"

    >
      <v-card class="text-center">
        <v-card-title class="text-center headline">We love to hear from you.</v-card-title>
        <v-card-subtitle class="mt-1">Hey {{ userName }}, <br>Any requests, complains, suggestions, scholarship,
          job, internship, HO ship, anything. Don't hesitate to
          reach out to us. May be we can help you.
        </v-card-subtitle>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <!--suppress HtmlUnknownTarget -->
            <v-container fluid>
              <v-row dense>
                <v-flex md4 xs12>
                  <!--suppress HtmlUnknownTarget -->
                  <v-img
                      v-if="showImage"
                      :src="feedbackImage"
                      aspect-ratio="1"
                      class="grey lighten-2 ma-1"
                      contain
                      height="150px" lazy-src="https://picsum.photos/id/11/100/60">
                    <template v-slot:placeholder>
                      <v-row
                          align="center"
                          class="fill-height ma-0"
                          justify="center"
                      >
                        <v-progress-circular color="grey lighten-5"
                                             indeterminate></v-progress-circular>
                      </v-row>
                    </template>

                  </v-img>
                </v-flex>
                <v-flex md8 xs12>

                  <v-textarea
                      v-model="feedbackData"
                      :rules="nameRules"
                      class="ma-1"
                      filled
                      label="Your Feedback"
                      name="input-7-1"
                      outlined
                  ></v-textarea>
                </v-flex>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn

              color="blue darken-1"
              text
              @click="feedbackDialogClose"
          >
            Close
          </v-btn>
          <v-btn
              :loading="feedbackLoading"
              color="green darken-1"
              text
              @click="sendFeedback"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>


  <!-- <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div>-->
</template>

<!--suppress JSUnresolvedVariable -->
<script>

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/messaging';


import {APIService} from "./APIService";
import ProfileComponent from "./components/ProfileComponent";
import localforage from "localforage";

const apiService = new APIService();

export default {
  components: {ProfileComponent},
  data: () => ({
    isMobile: window.innerWidth < 600,
    valid: false,
    showImage: true,
    nameRules: [
      v => !!v || 'Feedback is required',
    ],
    feedbackLoading: false,
    feedbackData: "",
    feedbackImage: "https://bad.src/not/valid",
    feedbackDialog: false,
    userDialogData: {
      name: "",
      img: ""
    },
    userProgress: false,
    userMCQsAttempted: 0,
    userDailyTestAttempted: 0,
    userRapidfireHS: 0,
    userDialogRoute: false,
    userDialogRouteData: {
      name: "",
      route: ""
    },
    drawer: false,
    loggedOutItems: [
      {name: "login", icon: "mdi-exit-to-app"},
      // {name: "schedule", icon: "calendar_today"},
    ],
    items: [],
    admins: [["Management", "people_outline"], ["Settings", "settings"]],
    updatesMenu: false,
    fav: false,
    updates: [],
    newUpdates: [],
    newUpdatesProgress: false,

    forumMenu: false,
    forumUpdates: [],
    newForumUpdates: [],
    newForumUpdatesProgress: false,


  }),
  props: {
    source: String
  },

  computed: {
    isDark() {
      return (this.$vuetify.theme.dark)
          ? 'dark'
          : 'light'
    },
    userDialog: {
      get() {
        return this.$store.getters.getanotherUserProfileDialog;
      },
      set(value) {
        this.$store.commit("setAnotherUserProfileDialog", value);
      }

    },

    // goDark() {
    //     return this.$store.getters.goDark;
    // },
    showProgress() {
      return this.$store.getters.showProgress;
    },
    authenticated() {
      return this.$store.getters.getUserAuth;
      // return JSON.parse(localStorage.getItem("authenticated"));
    },
    userName() {
      return this.$store.getters.getUserName;
    },
    photoUrl() {
      // console.log("PhotoURL: " + this.$store.getters.getPhotoUrl);
      return this.$store.getters.getPhotoUrl;
    }
  },
  mounted: function () {
    const self = this;


    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    }


    this.$nextTick(function () {

      window.addEventListener('resize', this.onResize, {passive: true});

      if (location.protocol === 'https:') {
        this.firebaseMessaging();
      }
      firebase.auth().onAuthStateChanged(user => {
        // eslint-disable-next-line no-console
        // console.log("user is "+user)
        if (user) {

          self.items = [
            {
              name: "home",
              icon: "mdi-home",
              to: "/"
            },
            {
              name: "rapidfire",
              icon: "mdi-shuffle-variant",
              to: "/rapidfire"
            },
            {
              name: "forum",
              icon: "mdi-account-group",
              to: "/forum",


            },
            {
              icon: 'mdi-clipboard-check-outline',
              text: 'practise',
              link: [
                {
                  icon: 'mdi-image-edit-outline',
                  text: 'ibqs',
                  to: '/ibqs'
                },
                {
                  icon: 'mdi-format-list-bulleted-square',
                  text: 'mcqs',
                  to: '/subjects'
                }
              ]
            },
            // {name: "tests", icon: "assignment_turned_in"},
            {
              icon: 'mdi-clipboard-text',
              text: 'Mock Tests',
              link: [
                {
                  text: 'Daily Tests',
                  to: '/daily-tests',
                  icon: 'mdi-calendar-today'
                },
                {
                  text: 'Weekly Tests',
                  to: '/weekly-tests',
                  icon: 'mdi-calendar-week'
                  // icon: 'mdi-facebook'

                },
                // {
                //     text: 'Monthly Tests',
                //     to: '/playersz/import',
                //     icon: 'mdi-calendar-month'
                //
                // }
              ]
            },
            // {
            //     name: "results", icon: "assessment",
            //     to: "/results"
            // },
            {
              name: "Feedback", icon: "mdi-comment", action: "feedback"
            },
            {
              name: "Subscription", icon: "mdi-currency-inr", to: "/subscribe"
            },
            {
              name: "FAQs", icon: "mdi-help",
              to: "/faqs"
            },
            {
              icon: 'mdi-tooltip-text',
              text: 'Policy',
              link: [
                {
                  text: 'Privacy Policy',
                  to: '/privacy-policy',
                  icon: 'mdi-script-text-outline',

                },
                {
                  text: 'Terms & Conditions',
                  to: '/tnc',
                  icon: 'mdi-script-text',


                }
              ]
            },

            {
              name: "logout", icon: "mdi-exit-to-app",
              to: "/logout"
            }
          ];
        } else {

          // No user is signed in.
          self.items = [{name: "login", icon: "exit_to_app"}];

        }
      });


      this.getUserUpdates();
      this.getForumUpdates();

      this.$store.commit("setIsMobile", this.isMobile);

    });


  },
  watch: {
    updatesMenu(newCount) {
      if (newCount) {
        this.newUpdatesProgress = true;
        const app = this;
        apiService.getUpdates().then(data => {
          app.newUpdatesProgress = false;
          if (JSON.stringify(data) !== JSON.stringify(app.updates)) {
            app.newUpdates = data;
          }
        });
      }
    },
    forumMenu(newCount) {
      if (newCount) {
        this.newForumUpdatesProgress = true;
        const app = this;
        apiService.getForumUpdates().then(data => {
          app.newForumUpdatesProgress = false;
          if (JSON.stringify(data) !== JSON.stringify(app.forumUpdates)) {
            app.newForumUpdates = data;
          }
        });
      }
    }

  },

  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },

    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const {height} = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    openSocialMedia(val) {
      if (val === 1) {
        window.open("https://www.facebook.com/dentalpockets", '_blank')
      } else if (val === 2) {
        window.open("https://www.instagram.com/dentalpockets", '_blank')
      } else if (val === 3) {
        window.open("https://www.twitter.com/dentalpockets", '_blank')
      } else if (val === 4) {
        window.open("https://t.me/dentalpockets", '_blank')
      } else if (val === 5) {
        window.open("https://play.google.com/store/apps/details?id=com.dentalguru.mcq&utm_source=webapp_dental_pocketes&pcampaignid=webapp_promotion", '_blank')
      }
    },
    feedbackDialogClose() {
      this.feedbackDialog = false;
      this.feedbackImage = null;
      this.showImage = false;

    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.$store.commit("setIsMobile", this.isMobile);

    },
    firebaseMessaging() {
      if (firebase.messaging.isSupported()) {
        let messaging = firebase.messaging();


        messaging.onMessage((payload) => {
          // eslint-disable-next-line no-console
          console.log('Message received. ', payload);
          //TODO: Show Dialog or something...
          // ...
          // eslint-disable-next-line no-console
          // console.log(payload);
        });
      }

    },
    sendFeedback() {
      if (this.$refs.form.validate()) {
        if (this.feedbackData.trim().length > 0) {
          const app = this;
          this.feedbackLoading = true;
          apiService.sendFeedback(this.feedbackImage.trim(), this.feedbackData).then(data => {

            // eslint-disable-next-line no-console
            console.log(data);
            app.feedbackLoading = false;
            app.feedbackImage = null;

            app.userProgress = false;
            app.feedbackDialog = false;
            app.feedbackImage = "https://bad.src/not/valid";
            app.feedbackData = "";

          });
        } else {
          this.feedbackData = "";
          alert("Feedback is required.");
        }
      }
    },
    async feedback() {
      this.feedbackDialog = true;

      // eslint-disable-next-line no-console
      // console.log("Hello Feedback");

      // noinspection JSUnresolvedVariable
      const el = this.$refs.getMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL',
        logging: false
      };

      try {
        this.feedbackImage = await this.$html2canvas(el, options);

      } catch (e) {
        this.feedbackImage = null;
        this.showImage = false;
        // eslint-disable-next-line no-console
        console.error(e);
        this.feedbackImage = "https://bad.src/not/valid";

      }

    },
    loadNewUpdates() {
      this.updates = this.newUpdates;
      this.newUpdates = [];
    },
    loadNewForumUpdates() {
      this.forumUpdates = this.newForumUpdates;
      this.newForumUpdates = [];
    },
    navigate(vals) {
      // 1 for new user
      // 2 for daily test started
      // 3 for practicing MCQs
      // 4 for practicing IBQs
      // 5 for rapidfire
      // 6 for Weekly test
      this.userDialogRoute = false;
      let val = parseInt(vals);
      if (val === 2) {
        //    Navigate Daily Test
        this.$router.push("daily-tests");

      } else if (val === 3) {
        //    Navigate MCQs
        this.$router.push("subjects");

      } else if (val === 4) {
        //    Navigate IBQs
        this.$router.push("ibqs");

      } else if (val === 5) {
        //    Navigate Rapidfire
        this.$router.push("rapidfire");
      } else if (val === 6) {
        //    Navigate Weekly Test

      }

    },
    getForumUpdates() {
      const app = this;
      apiService.getForumUpdates().then(data => {
        // eslint-disable-next-line no-console
        // console.log("data", data)
        app.forumUpdates = data;
      });
    },
    getUserUpdates() {
      const app = this;
      apiService.getUpdates().then(data => {
        app.updates = data;
      });
    },
    getUserData(uid) {
      const app = this;
      apiService.getUserDetails(uid).then(data => {

        // eslint-disable-next-line no-console
        // console.log(data);
        app.userDialogData = data;
        app.userDialogData.user.userMCQsAttempted = data.user.m;
        app.userDialogData.user.userDailyTestAttempted = data.user.d;
        app.userDialogData.user.userRapidfireHS = data.user.r;
        // app.userMCQsAttempted = data.m;
        // app.userDailyTestAttempted = data.d;
        // app.userRapidfireHS = data.r;

        app.userProgress = false;

      });
    },
    userClick(item) {
      // eslint-disable-next-line no-console
      // console.log(item);
      this.updatesMenu = false;
      this.userProgress = true;
      // noinspection JSUnresolvedVariable
      this.getUserData(item.u);

      this.userDialogData = {
        name: item.name,
        img: item.img
      };
      this.userDialog = true;
      this.$store.commit("setAnotherUserProfileDialog", true);

    },
    pClick(item) {
      // eslint-disable-next-line no-console
      // console.log(item);
      this.updatesMenu = false;
      // noinspection JSUnresolvedVariable
      if (parseInt(item.pd) !== 1) {
        // noinspection JSUnresolvedVariable
        this.userDialogRouteData = {
          name: item.p,
          route: item.pd,
        };
        this.userDialogRoute = true;
      }


    },
    showForum() {
      this.forumMenu = false;
      this.$router.push("/forum");

    },
    showForumPost(item) {
      // eslint-disable-next-line no-console
      // console.log(item);
      this.$store.commit("setForumPost", item);
      let id = item.id;

      let app = this;
      localforage.setItem('post' + id, item).then(function () {
        // Do other things once the value has been saved.
        // eslint-disable-next-line no-console
        // console.log(value);

        app.forumMenu = false;

        app.$router.push("/forum/post/" + id);
      }).catch(function (err) {
        // This code runs if there were any errors
        // eslint-disable-next-line no-console
        console.log(err);
      });

    },
    goHome() {
      this.$router.push("/")
    },
    nomenu() {
      return false;
    },
    showQuestionNumber(i) {
      // console.log(i);
      this.$store.commit("setQuestionNumber", i);
    },
    /**
     * @return {string}
     */
    UC(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getColor(s) {
      if (s % 2 === 0) {
        return "red";
      } else {
        return "cyan";
      }
    },
    goto(name, path) {
      if (path) {
        this.$router.push({name: path});
      } else {
        this.$router.push({name: name});
      }
    },
  },
  beforeDestroy: function () {
    if (typeof window !== 'undefined') {
      // noinspection ES6ShorthandObjectProperty
      window.removeEventListener('resize', this.onResize, {passive: true})
    }
  },
};
</script>


<style>

#app {
  /* .no_selection { */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  /* } */
}


.showPointer {
  cursor: pointer;
}

[v-cloak] {
  display: none;
}

/*noinspection CssUnusedSymbol*/
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

/*noinspection CssUnusedSymbol*/
.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>

