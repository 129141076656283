<template>


  <v-card

      class="mx-auto"
      elevation="24"
  >
    <v-toolbar
        :color="($vuetify.theme.dark) ? 'dark' : 'white'"

    >
      <v-toolbar-title>MCQ of the day</v-toolbar-title>
    </v-toolbar>


    <!-- <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="200px"></v-img> -->
    <v-card-text width="100%">
      <!--        <div style="width:100%">-->
      <!--                    <div class="headline indigo&#45;&#45;text">-->
      <!--                        MCQ of the day-->
      <!--                        <hr class="red darken-1"/>-->
      <!--                    </div>-->
      <div class="title text--primary font-weight-regular">{{ mcq.q }}</div>

      <v-radio-group v-model="radios" :mandatory="false" class="radio-group-full-width" column>
        <v-radio
            v-for="(option, index) in mcq.o"
            :key="index"
            :class="getBorderColor(radios, mcq.c, index)"
            :label="option"
            :value="index"
            class="pa-1"
            style="margin:3px"
        >
        </v-radio>
      </v-radio-group>
      <!--        </div>-->
    </v-card-text>

    <v-card-actions>

      <v-btn v-if="!show" color="primary" dark text @click="submit">Explanation</v-btn>
      <v-spacer/>
      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-slide-y-transition>
      <v-card-text v-show="show" v-html="mcq.d"/>
    </v-slide-y-transition>
  </v-card>

</template>
<script>
export default {
  props: {
    mcq: {
      type: Object
    }
  },
  watch: {

    // eslint-disable-next-line no-unused-vars
    radios(newCount) {
      if (newCount != null) {
        // console.log(`We have ${newCount} banana now, yay!`);
        // if (this.mcq.c == newCount) {
        // }
        this.show = !this.show;

      }
    }
  },
  data: () => ({
    show: false,
    radios: null
  }),
  methods: {
    submit() {
      // console.log(id)
      //   this.$router.push({ name: "solution", params: { id: id } });
      this.show = !this.show;
    },
    getBorderColor: function (ua, ca, index) {
      // console.log(ua + "----------" + ca);
      if (this.radios != null) {
        if (ua === ca && index === ca) {
          return "greenBorder";
        } else if (index === ua) {
          return "redBorder";
        } else if (index === ca) {
          return "greenBorder";
        } else {
          return "";
        }
      }
    }
  }
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.greenBorder {
  border: 1px solid green;
  /* padding: 2px; */
  margin: 2px;
}

.redBorder {
  border: 1px solid red;
  /* padding: 2px; */
  margin: 2px;
}

.v-input__control {
  width: 100% !important
}

.v-label {
  width: 100% !important
}

.radio-group-full-width >>> .v-input__control {
  width: 100%
}

/*.someClass {*/
/*  width: 100%;*/
/*  height: 3em;*/
/*  color: green;*/
/*}*/

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>

