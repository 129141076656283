export const config = {
    apiKey: "AIzaSyB8UaNltCkHVvR03y1XbGC-MnAuDevQxy8",
    // authDomain: "dental-pockets-android-9b88b.firebaseapp.com",
    authDomain: "app.dentalpockets.com",
    databaseURL: "https://dental-pockets-android-9b88b.firebaseio.com",
    projectId: "dental-pockets-android-9b88b",
    storageBucket: "dental-pockets-android-9b88b.appspot.com",
    messagingSenderId: "444390135124",
    appId: "1:444390135124:web:440cb92ad4386731"

};