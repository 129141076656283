<template>
  <div class="full-Width">
    <v-alert
        border="left"
        prominent
        type="error"
    >
      <v-row align="center">
        <v-col class="grow">
          You have not purchased the subscription yet. It starts from just ₹ 699/month*.

        </v-col>
        <v-col class="shrink">
          <v-btn class="ml-2" color="white" outlined @click="purchase()">Purchase Now</v-btn>

        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  methods: {
    purchase() {
      this.$router.push("subscribe");
    },
    // schedule() {
    //   this.$router.push("schedule");
    // }
  }
};
</script>


<style scoped>
.full-Width {
  width: 100%;
}
</style>

