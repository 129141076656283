import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);


function convertToDDMMYYYY(string) {
    const savedDate = new Date(string);
    return savedDate.getDate();

}


export default new Vuex.Store({
    state: {
        currentQuestionNumber: 1,
        name: '',
        email: '',
        photoUrl: "",
        id: '',
        authenticated: false,
        uid: '',
        userName: '',
        isMobile: false,
        practisingSubject: "",
        mcqsAttempted: 0,
        timeUP: false,
        showProgress: false,
        showSolutionFullScreenDialog: false,
        showMeritListDialog: false,
        darkMode: true,
        testsErrorDialog: false,
        anotherUserProfileDialog: false,
        isSubscribed: false,
        subscriptionCreated: "",
        subscriptionValidTill: '',
        subscriptionValidFrom: '',

        forumPost: {},
    },
    mutations: {
        setSubscriptionValidTill(state, n) {
            state.subscriptionValidTill = n;
        },
        setSubscriptionValidFrom(state, n) {
            state.subscriptionValidFrom = n;
        },
        setIsMobile(state, n) {
            state.isMobile = n;
        },
        setForumPost(state, n) {
            state.forumPost = n;
        },
        setSubscriptionsStatus(state, n) {
            state.isSubscribed = n;
            localStorage.setItem("isSubscribed", n);
        },
        setAnotherUserProfileDialog(state, n) {
            state.anotherUserProfileDialog = n;
        },
        settestsErrorDialog(state, n) {
            state.testsErrorDialog = n;
        },
        setshowMeritListDialog(state, n) {
            state.showMeritListDialog = n;
        },
        setshowSolutionFullScreenDialog(state, n) {
            state.showSolutionFullScreenDialog = n;
        },
        setShowProgress(state, n) {
            state.showProgress = n;
        },
        timeUP(state, n) {
            state.timeUP = n;
        },
        setMcqsAttempted(state, n) {
            state.mcqsAttempted = parseInt(n);
            let object = {value: state.mcqsAttempted, timestamp: new Date().getTime()};
            localStorage.setItem("attemptedMCQs", JSON.stringify(object));
        },
        // eslint-disable-next-line no-unused-vars
        increaseCounter(state, n) {
            let object = JSON.parse(localStorage.getItem("attemptedMCQs"));
            if (object) {
                state.mcqsAttempted = parseInt(state.mcqsAttempted) + parseInt(n);

                let dateString = convertToDDMMYYYY(object.timestamp);
                let now = convertToDDMMYYYY(new Date().getTime());

                if (dateString === now) {
                    object = {value: state.mcqsAttempted, timestamp: new Date().getTime()};
                    localStorage.setItem("attemptedMCQs", JSON.stringify(object));
                } else {
                    state.mcqsAttempted = 1;
                    object = {value: state.mcqsAttempted, timestamp: new Date().getTime()};
                    localStorage.setItem("attemptedMCQs", JSON.stringify(object));
                }

            } else {
                state.mcqsAttempted = 1;
                object = {value: state.mcqsAttempted, timestamp: new Date().getTime()};
                localStorage.setItem("attemptedMCQs", JSON.stringify(object));
            }


            // console.log("Time In Json: " + dateString + " time in NOW: " + now);


        },
        SetPractiseSubject(state, n) {
            state.practisingSubject = n;
        },
        setQuestionNumber(state, n) {
            // mutate state
            state.currentQuestionNumber = n;
        },
        setUser(state, n) {

            state.name = n.displayName;
            state.email = n.email;
            state.id = n.uid;
            // state. n.emailVerified;
            state.photoUrl = n.photoURL;
            window.localStorage.setItem("name", n.displayName);
            window.localStorage.setItem("photoUrl", n.photoURL);

        },
        setAuthenticated(state, n) {
            state.authenticated = n;
        },
        setUid(state, n) {
            state.uid = n;
            window.localStorage.setItem("uid", n);

        },
        setForumUserName(state, n) {
            state.userName = n;

        }
    },

    getters: {
        getSubscriptionValidFrom: state => {
            return state.subscriptionValidFrom
        },
        getSubscriptionValidTill: state => {
            return state.subscriptionValidTill
        },
        getIsMobile: state => {
            return state.isMobile;
        },
        getForumPost: state => {
            return state.forumPost;
        },
        getSubscriptionStatus: state => {
            // let subscribed = false;
            // if (localStorage.getItem("isSubscribed")) {
            //     subscribed = JSON.parse(localStorage.getItem("isSubscribed"));
            //     // eslint-disable-next-line no-console
            //     console.log(subscribed);
            //     state.isSubscribed = subscribed;
            //
            // } else {
            //     state.isSubscribed = false;
            // }
            return state.isSubscribed;
        },
        getanotherUserProfileDialog: state => {
            return state.anotherUserProfileDialog;
        },
        getErrorDialogTest: state => {
            return state.testsErrorDialog;
        },

        goDark: state => {
            return state.darkMode;
        },
        dshowMeritListDialog: state => {
            return state.showMeritListDialog;
        },
        showFullScreenDialog: state => {
            return state.showSolutionFullScreenDialog;
        },
        showProgress: state => {
            return state.showProgress;
        },
        getTimeUp: state => {
            return state.timeUP;
        },
        getCurrentQuestionNumber: state => {
            return state.currentQuestionNumber
        },
        getPhotoUrl: state => {
            return state.photoUrl
        },
        getUserName: state => {
            return state.name
        },
        getUserEmail: state => {
            return state.email
        },
        getUserAuth: state => {
            return state.authenticated;
        },
        getProfileUserName: state => {
            return state.userName
        },

        getPractisingSubject: state => {
            return state.practisingSubject;
        },
        getTodaysMCQAttempted: state => {
            return state.mcqsAttempted;
        }
    },
    actions: {}
})
