<template>
    <v-card

            class="mx-auto overflow-y-auto"
            elevation="24">

        <v-img
            class="white--text align-end"
            contain
            height="200"
            src="https://res.cloudinary.com/drdx39vgf/image/upload/v1583508033/WebApp/bitmap.png"
        >
        </v-img>
        <v-card-title>Give your feedback</v-card-title>
        <v-card-subtitle class="pb-0">Would you recommend others to use Dental Pockets?</v-card-subtitle>
        <v-card-actions>
            <v-btn
                color="primary"
                text
                @click="askFeedback">
              Click Here
              <v-icon right> mdi-chevron-right</v-icon>
            </v-btn>
        </v-card-actions>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">

            <v-card full-height full-width v-if="!formSubmitted" width="100%">
                <v-toolbar color="indigo" dark>
                    <v-btn @click="dialog = false" dark icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Give your valuable feedback</v-toolbar-title>
                </v-toolbar>
                <v-card-title class="headline font-weight-light mt-8">How likely would you recommend Dental Pockets
                    to
                    your colleague or friend?
                </v-card-title>

                <v-row class="ml-4 mr-4 mt-8">
                    <v-col sm="6">Not likely at all</v-col>
                    <v-col class="text-right" sm="6">Extremely likely</v-col>
                </v-row>
                <v-row class="text-center" no-gutters>
                    <v-col sm="1">&nbsp;</v-col>
                    <v-col
                            :key="n"
                            @click="select(n)"
                            sm="1"
                            v-for="n in 10"
                    >
                        <v-card
                                :class="{ selected: n===selectedVal }"
                                class="pa-2 text-center"
                                outlined
                                tile
                        >
                            {{n}}
                        </v-card>
                    </v-col>
                    <v-col sm="1">&nbsp;</v-col>
                </v-row>
                <div v-if="selectedVal">
                    <v-card-title class="headline hidden-sm-and-down font-weight-light mt-2 ml-3">

                        {{summaryText}}
                    </v-card-title>
                    <v-card-title class="headline hidden-md-and-up font-weight-light mt-2 ml-1">

                        {{summaryText}}
                    </v-card-title>

                    <v-col class="mt-1 mb-12 text-center" cols="12">
                        <div class="text-center">
                            <v-textarea
                                v-model="suggestion"
                                class="mx-4"
                                prepend-icon="mdi-comment"
                                rows="4"
                            ></v-textarea>
                        </div>

                    </v-col>
                    <v-col class="mt-1 " cols="12">

                        <div class="text-center mt-2 mb-12">
                            <v-btn :loading="loading" @click="sendSuggestion" color="indigo" dark large>
                                <v-icon left>mdi-send</v-icon>
                              Send
                            </v-btn>
                        </div>
                    </v-col>


                </div>

            </v-card>

          <!--suppress HtmlDeprecatedAttribute -->
          <v-card v-else align="center" full-width height="100%" justify="center" width="100%">
            <v-toolbar color="indigo" dark>
              <v-btn @click="dialog = false" dark icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Thank You!!!</v-toolbar-title>
            </v-toolbar>
            <div class="mt-12" style="justify-content: center;">
              <v-icon color="green" large>mdi-check</v-icon>
              <v-card-subtitle>We highly appreciate you taking time for reaching out to us and sending your
                suggestions.<br>Thank
                        You Again.
                    </v-card-subtitle>


                    <v-btn @click="dialog = false" color="indigo" dark large>
                        Close
                    </v-btn>
                </div>
            </v-card>


        </v-dialog>
    </v-card>
</template>

<script>
// noinspection ES6PreferShortImport
import {APIService} from "../APIService";

const apiService = new APIService();

export default {
  name: "Suggestions",
  data() {
    return {
      dialog: false,
      selectedVal: null,
      summaryText: null,
                loading: false,
                suggestion: "",
                formSubmitted: false,


            }
        },
        computed: {},
        methods: {
            askFeedback() {
                this.dialog = true;
            },
            select(n) {
                this.selectedVal = n;
                if (n >= 9) {
                    this.summaryText = "Thank You! Do let us know what you liked most about us"
                } else if (n < 9 && n >= 7) {
                    this.summaryText = "Please let us know how we can improve"
                } else {
                    this.summaryText = "We are terribly sorry that you are having a poor experience with us.\nPlease help us by providing details about how we can improve "
                }
            },
            sendSuggestion() {
                this.loading = true;
                apiService.sendSuggestion(this.selectedVal, this.suggestion).then(data => {
                        if (data.status === 200) {
                            this.loading = false;
                            this.formSubmitted = true;
                        }

                    }
                )
            }

        }
    }
</script>

<style scoped>
    .selected {
        background-color: #3f51b5 !important;
        color: white;
    }
</style>