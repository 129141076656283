<template>

  <v-card class="mx-auto"
          elevation="24">
    <v-toolbar
        :color="($vuetify.theme.dark) ? 'dark' : 'white'"

    >
      <v-toolbar-title>One Liner of the day</v-toolbar-title>
    </v-toolbar>

    <v-card-text primary-title width="100%">
      <div style="width:100%">
        <!--                    <div class="headline indigo&#45;&#45;text">One Liner of the day</div>-->
        <!--                    <hr class="red darken-1"/>-->
        <div class="subtitle-1 font-weight-regular ma-2" v-html="oneliner.text"></div>
      </div>
    </v-card-text>
  </v-card>

</template>
<script>
export default {
  props: {
    oneliner: {
      type: Object
    }
  },
  data: () => ({
    show: false
  }),
  methods: {
    submit() {
      // console.log(id)
      //   this.$router.push({ name: "solution", params: { id: id } });
      this.show = !this.show;
    }
  }
};
</script>