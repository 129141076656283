<template>
    <v-card class="overflow-y-auto">

        <v-list :style="`background-color:  ${info.color}`" class="text-center ">
            <v-list-item dark>
                <v-list-item-content>
                    <v-list-item-title class="headline">{{info.name}}</v-list-item-title>

                </v-list-item-content>
                <v-list-item-icon>
                    <v-btn @click="closeDialog" dark icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>

        <v-card-text>
            <v-row align="center" class="mt-3" justify="center">
                <v-avatar
                        class="profile"
                        color="grey"
                        size="120"

                >
                    <!--suppress HtmlUnknownTarget -->
                    <v-img :src="info.avatar"/>
                </v-avatar>
            </v-row>
            <v-row align="center" justify="center">
                <div class="text-center mt-2">{{info.un}}</div>
            </v-row>
        </v-card-text>
        <v-card-text class="text-center">
            <h4 class="title font-weight-light">
                {{info.dj}}
            </h4>
            <div v-if="info.shareGender">
                <h4 class="title font-weight-light" v-if="info.gender ==='Other'">
                    <v-icon color="grey" left>mdi-gender-transgender</v-icon>
                </h4>
                <h4 class="title font-weight-light" v-else-if="info.gender ==='Female'">
                    <v-icon color="grey" left>mdi-gender-female</v-icon>
                </h4>
                <h4 class="title font-weight-light" v-else-if="info.gender ==='Male'">
                    <v-icon color="grey" left>mdi-gender-male</v-icon>
                </h4>
            </div>
            <h4 class="font-weight-light" v-if="info.shareLocation">
                <v-icon color="grey" left>mdi-map-marker</v-icon>
                {{info.city}}, {{info.state}}
            </h4>

            <!--                        <h6 class="category text-gray font-weight-thin mb-3">-->

            <!--                        </h6>-->

            <!--                        <h4 class="card-title font-weight-light"></h4>-->
            <p class="card-description font-weight-light">{{info.about}}</p>
            <!--                        <v-btn-->
            <!--                                class="font-weight-light"-->
            <!--                                color="success"-->
            <!--                                rounded-->
            <!--                        >Follow-->
            <!--                        </v-btn>-->
        </v-card-text>
        <v-card-text v-if="user">
            <span class=" caption font-weight-bold text-left">User Statistics:</span>
            <v-list>
                <template>
                    <v-list-item>
                        <v-list-item-action>
                            {{user.userMCQsAttempted}}
                        </v-list-item-action>
                        <v-list-item-title>MCQs Attempted</v-list-item-title>
                    </v-list-item>
                    <v-divider/>

                    <v-list-item>
                        <v-list-item-action>
                            {{user.userDailyTestAttempted}}
                        </v-list-item-action>
                        <v-list-item-title>Daily Tests Attempted</v-list-item-title>
                    </v-list-item>
                    <v-divider/>

                    <v-list-item>
                        <v-list-item-action>
                            {{user.userRapidfireHS}}
                        </v-list-item-action>
                        <v-list-item-title>Rapidfire Highscore</v-list-item-title>
                    </v-list-item>

                    <v-divider/>


                </template>

            </v-list>
        </v-card-text>

    </v-card>
</template>
<script>
    export default {
        name: 'ProfileComponent',
        props: {
            info: {},
            user: {}
        },
        methods: {
            closeDialog() {
                this.$store.commit("setAnotherUserProfileDialog", false)
            }
        },
        beforeDestroy() {
            // this.info = {};
            // this.user = {};
        }
    }
</script>
<style>

</style>
