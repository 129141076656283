import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import vuetify from './plugins/vuetify';
import VueHtml2Canvas from 'vue-html2canvas';

import VueKeepScrollPosition from 'vue-keep-scroll-position'
// import firebase from 'firebase/app';
// import 'firebase/auth'
// import 'firebase/messaging';

// import firebase from 'firebase/compat/app';
// import firebase from 'firebase/compat/app';
import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';

// import {getAuth} from "firebase/auth";
// import {getMessaging, isSupported, getToken} from "firebase/messaging";

import {config} from "./firebase";

const firebaseApp = firebase.initializeApp(config);


const firebaseAuth = firebase.auth(firebaseApp);
// const firebaseMessaging = firebase.messaging(firebaseApp);



// const firebaseAuth = getAuth(firebaseApp);
// const firebaseMessaging = getMessaging(firebaseApp);
// const isMessagingSupported = isSupported();


// noinspection ES6CheckImport
import VueGoogleCharts from 'vue-google-charts'
// import firebase from "firebase";
import './registerServiceWorker'
import Croppa from 'vue-croppa'
import linkify from "./plugins/vue-linkify-hashtags-mentions.min.js";
import localforage from "localforage";
// import VTooltip from "v-tooltip";
// import "./assets/tooltip.css";

Vue.use(Croppa);

// import VueLocalForage from 'vue-localforage'
localforage.config({
    name: 'dentalpockets-webapp'
});

// import linkify from "./components/linkify";

// import vueMentionable from 'vue-mentionable';

// Vue.use(VTooltip);

// Vue.use(VueLocalForage)


Vue.directive("linkified", linkify);

// Vue.use(vueMentionable);
Vue.use(VueKeepScrollPosition);

Vue.use(VueGoogleCharts);
Vue.use(VueMeta);

Vue.use(VueHtml2Canvas);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {

    if (!to.meta.protected) { //route is public, don't check for authentication
        next()
    } else {  //route is protected, if authenticated, proceed. Else, login
        if (firebase.apps.length > 0) {

            let unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
                if (user) {
                    next()
                } else {
                    // noinspection JSIgnoredPromiseFromCall
                    router.push('/login')
                }
            });

            unsubscribe()
        } else {
            // noinspection JSIgnoredPromiseFromCall
            router.push('/login')
        }
    }
});


Vue.config.productionTip = false;


new Vue({
    vuetify,
    router,
    created() {
        const self = this;
        // firebase.initializeApp(config);
        firebaseAuth.onAuthStateChanged((user) => {
            if (user) {
                self.$store.commit("setUser", user);
                self.$store.commit("setAuthenticated", true);
            } else {

                self.$store.commit("setAuthenticated", false);
            }
        });

        if (location.protocol === 'https:') {
            if (firebase.messaging.isSupported()) {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        // eslint-disable-next-line no-console
                        // console.log('Notification permission granted.');
                        // Get Token
                        // firebaseMessaging.getToken()
                        const messaging = firebase.messaging()
                        // messaging.usePublicVapidKey("BPArK6H1Qo12-EXVsSmFBA2FZg1FeohhZYboF4BgrB3wEj8_SeSEuXnK8EXC8GdUUFjjTpRu7kH6SxCMHQo2g-8"); // 1. Generate a new key pair

                        messaging.getToken({vapidKey: '"BPArK6H1Qo12-EXVsSmFBA2FZg1FeohhZYboF4BgrB3wEj8_SeSEuXnK8EXC8GdUUFjjTpRu7kH6SxCMHQo2g-8"'}).then((currentToken) => {
                            // eslint-disable-next-line no-console
                            console.log(currentToken)
                            if (currentToken) {
                                // Send the token to your server and update the UI if necessary
                                // ...
                            } else {
                                // Show permission request UI
                                // eslint-disable-next-line no-console
                                console.log('No registration token available. Request permission to generate one.');
                                // ...
                            }

                        });
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('Unable to get permission to notify.');
                    }
                });
            }
        }
    },
    store,
    render: h => h(App)
}).$mount('#app');
