<template>
  <v-container v-cloak fluid grid-list-lg>
    <v-layout row wrap>

      <v-flex v-if="!subscribed" xs12>
        <PaymentAlert/>
      </v-flex>
      <v-flex v-if="Object.keys(toppers.daily).length === 0" xs12>
        <div>
          <v-alert
              v-model="alert"
              border="left"
              color="cyan"
              colored-border
              dismissible
              elevation="2"
              icon="mdi-information-variant"
          >
            <span @click="alertClicked(1)">Be the <strong>first</strong> one to appear Daily Test.</span>

          </v-alert>
        </div>
      </v-flex>
      <v-flex v-if="Object.keys(toppers.weekly).length === 0" xs12>
        <div>
          <v-alert
              v-model="alert"
              border="left"
              color="cyan"
              colored-border
              dismissible
              elevation="2"
              icon="mdi-information-variant"
          >
            <span @click="alertClicked(2)">Be the <strong>first</strong> one to appear Weekly Test.</span>

          </v-alert>
        </div>
      </v-flex>
      <v-flex v-if="data.msg" xs12>
        <AdminsDeskComponent :data="data.msg"/>
        <!--        <v-flex>-->
        <!--          <v-card width="100%">-->
        <!--            <v-card-title class="headline">-->

        <!--              <div style="width:100%">-->
        <!--                <div class="headline font-weight-light indigo&#45;&#45;text">From Admin's Desk</div>-->
        <!--                <hr class="blue darken-1"/>-->
        <!--              </div>-->

        <!--            </v-card-title>-->


        <!--            <div class="pa-2 subtitle-1" v-html="data.msg">Listen to your favorite artists and albums-->
        <!--              whenever and wherever, online and offline.-->
        <!--            </div>-->

        <!--          </v-card>-->
        <!--        </v-flex>-->
      </v-flex>

      <v-flex v-if="Object.keys(toppers.rapidfire).length > 0" md6 xs12>
        <NewTopperComponent :colors="colors" :toppers="toppers.rapidfire" title='Rapidfire Toppers'/>
      </v-flex>
      <v-flex v-if="Object.keys(toppers.daily).length > 0" md6 xs12>
        <NewTopperComponent :colors="colors" :toppers="toppers.daily" title="Today's Test Toppers"/>

      </v-flex>
      <v-flex v-if="Object.keys(toppers.weekly).length > 0" md6 xs12>
        <NewTopperComponent :colors="colors" :toppers="toppers.weekly" title="Weekly Test Toppers"/>
      </v-flex>
      <v-flex md6 xs12>
        <Suggestions/>
      </v-flex>


      <!--suppress JSUnresolvedVariable -->
      <v-flex v-if="data.mcqsdata" md6 xs12>
        <!--suppress JSUnresolvedVariable -->
        <NewMCQComponent :mcq="data.mcqsdata"/>
      </v-flex>
      <v-flex v-if="data.ibqsdata" md6 xs12>
        <NewIBQComponent :ibq="data.ibqsdata"/>
      </v-flex>
      <!--suppress JSUnresolvedVariable -->
      <v-flex v-if="data.onelinerdata" xs12>
        <!--suppress JSUnresolvedVariable -->
        <NewOneLinerComponent :oneliner="data.onelinerdata"/>
      </v-flex>
      <v-flex v-if="chartData != null && chartData.length > 1" xs12>

        <v-card class="mx-auto"
                elevation="24">

          <v-toolbar
              :color="($vuetify.theme.dark) ? 'dark' : 'white'"
          >
            <v-toolbar-title>MCQs Attempted in past week</v-toolbar-title>
          </v-toolbar>

          <v-card-text width="100%">
            <div style="width:100%">
              <!--                <div class="headline indigo&#45;&#45;text">MCQs Attempted in past week</div>-->
              <!--                <hr class="red darken-1"/>-->
              <GChart :data="chartData" :options="chartOptions" class="ma-2" type="ColumnChart"/>
            </div>
          </v-card-text>
        </v-card>

      </v-flex>
      <v-flex v-else xs12>

        <v-card class="mx-auto"
                elevation="24">

          <v-toolbar
              :color="($vuetify.theme.dark) ? 'dark' : 'white'"

          >
            <v-toolbar-title>MCQs Attempted in past week</v-toolbar-title>
          </v-toolbar>

          <v-card-text width="100%">
            <div style="width:100%;">
              <!--                <div class="headline indigo&#45;&#45;text">MCQs Attempted in past week</div>-->
              <!--                <hr class="red darken-1"/>-->
              <p class="subtitle-1 ma-2">No Data Found.
                <router-link :to="'subjects'"> Click Here or Practise</router-link>
                from Menu to start practising.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>


// import MCQComponent from "../components/MCQComponent";
// import IBQComponent from "../components/IBQComponent";
// import OneLinerComponent from "../components/OneLinerComponent";
import PaymentAlert from "../components/PaymentAlert";
// noinspection ES6CheckImport
import {GChart} from "vue-google-charts";
// noinspection ES6PreferShortImport
import {APIService} from "../APIService";
// import ToppersComponent from "../components/ToppersComponent";
import Suggestions from "../components/Suggestions";
import NewTopperComponent from "@/components/NewTopperComponent";
import AdminsDeskComponent from "@/components/AdminsDeskComponent";
import NewMCQComponent from "@/components/NewMCQComponent";
import NewIBQComponent from "@/components/NewIBQComponent";
import NewOneLinerComponent from "@/components/NewOneLinerComponent";

const apiService = new APIService();
export default {
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {
        name: 'description',
        content: "NEET, NBDE, Dental Exams, PGI, JIPMER, preparation of dental exams now easy"
      }
    ]
  },
  components: {
    NewOneLinerComponent,
    NewIBQComponent,
    NewMCQComponent,
    AdminsDeskComponent,
    NewTopperComponent,
    Suggestions,
    // ToppersComponent,
    // MCQComponent,
    // IBQComponent,
    // OneLinerComponent,
    PaymentAlert,
    GChart,

  },
  // watch: {
  //     subscribed(newV, old) {
  //
  //     }
  // },

  data() {
    return {
      alert: true,
      userDialog: false,
      userDialogData: [],
      data: [],
      // subscribed: false,
      chartData: {},
      chartOptions: {
        chart: {
          title: "MCQs Attempted in Last 7 Days "
        }
      },
      colors: [
        '#D4AF37',
        '#ACACAC',
        '#cc6633'
      ],
      toppers: {
        daily: [],
        rapidfire: [],
        weekly: []
      },
    };
  },
  computed: {
    subscribed() {
      return this.$store.getters.getSubscriptionStatus;
    }
  },
  created() {
    this.$store.commit("setShowProgress", true);

    if (localStorage.getItem("homedata")) {
      this.data = JSON.parse(localStorage.getItem("homedata"));
      this.toppers = this.data.toppers;

      // console.log("Loaded Home From Cache");
      this.$store.commit("setShowProgress", false);
    }
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered

      this.getDataFromServer();
    });
  },
  methods: {
    alertClicked(val) {
      if (val === 1) {
        this.$router.push({'name': 'daily-tests'})
      } else {
        this.$router.push({'name': 'weekly-tests'})
      }
    },
    getDataFromServer() {
      const app = this;
      apiService.getDataFromHome(app).then(data => {
        if (data.status === 200) {
          app.data = data.data;
          app.data.ibqsdata.h = "IBQ of the day";


          // eslint-disable-next-line no-console
          // console.log(data);

          app.chartData = data.data.chart;
          app.toppers = data.data.toppers;

          // eslint-disable-next-line no-console
          // console.log(data.data.toppers);


          app.$store.commit("setShowProgress", false);

          // let ip = data.ip;


          // apiService.getUserLocation(ip);

          app.$store.commit("setSubscriptionsStatus", data.subscribed);

          localStorage.setItem("homedata", JSON.stringify(data.data));
          // noinspection JSUnresolvedVariable
          localStorage.setItem("expiryDate", data.expiry);
        } else {
          // eslint-disable-next-line no-console
          console.log(data.status);
          app.$router.replace("/logout");
        }
      });
    }
  }
};
</script>
<!--suppress CssUnusedSymbol -->
<style>
[v-cloak] {
  display: none;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>