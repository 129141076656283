<template>

  <v-card class="mx-auto"
          elevation="24"
  >
    <v-toolbar
        :color="($vuetify.theme.dark) ? 'dark' : 'white'"

    >
      <v-toolbar-title>From Admin's Desk</v-toolbar-title>
    </v-toolbar>
    <!--    <div  class="headline font-weight-light indigo&#45;&#45;text">From Admin's Desk</div>-->

    <v-card-text class="ma-2 subtitle-1">
      <div v-html="data">Listen to your favorite artists and albums
        whenever and wherever, online and offline.
      </div>
    </v-card-text>


  </v-card>
</template>

<script>
export default {
  name: "AdminsDeskComponent",
  props: {
    data: {},

  },
}
</script>

<style scoped>

</style>